/*--------------------------------------------------------------
# Widgets
--------------------------------------------------------------*/
.widget {
	margin-block-start: 0;	
}

.widget-area .widget-title {
	font-size: 17px;
    line-height: 24px;
    padding: 0 0 15px;
	margin: 0;
}
.widget-area a:not(.button) {
	color: var(--global-palette3);
	&:hover {
		color: var(--global-palette-highlight);
	}
}
.widget-area .widget {
	margin-inline-start: 0;
	margin-inline-end: 0;
}
.widget {
	li, 
	.tagcloud, 	
	.wp-block-tag-cloud, 
	.widget_product_tag_cloud{
		list-style: none;
		margin: 0;
		position: relative;
		a , > span{
			padding-block: 6px;
			display: inline-block;
			vertical-align: top;
		}
	}
	.wp-block-latest-posts li, 
	.product_list_widget li, 
	.widgets-blog-posts li,
	.wp-block-latest-comments li, 
	&.widget_recent_comments ul li,
	&.widget_rss ul li {
		padding-block: 4px;
		margin-block: 6px;
		clear: both;
		overflow: hidden;
		a , > span{
			padding: 0;
		}
	}
	.wp-block-latest-comments__comment-link, 
	.wp-block-latest-posts li > a {
		word-break: break-word;
		display: block;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		padding: 0;
		margin: 0;
	  }
}
.primary-sidebar.widget-area {
	.widget-title {		
		padding: 25px 25px 9px 25px;
		margin-block-end: 15px;
		position: relative;
	}
	ul,ol {
		padding: 0 25px;
	}
	.widget_price_filter {
		form {
			padding: 25px 25px 0;
		}
	}
	.widget_product_tag_cloud {
		.tagcloud {
			padding: 0 25px;
		}
	}
	.widget_media_image{
		.wp-block-image{
			padding: 0 15px;
		}
	}
}
.widget .wp-block-latest-posts__featured-image.alignleft {
	margin-inline-end: 1em;
}
.wp-block-latest-comments .avatar, 
.wp-block-latest-comments__comment-avatar {
	margin-block-start: 5px;
}
.widget_archive ul,
.widget_categories ul,
.widget_meta ul,
.widget_nav_menu ul,
.widget_recent_comments u
.widget_pages ul,
.widget_recent_entries ul,
.widget_rss ul {
	margin: 0;
	padding: 0;
	list-style-type: none;
}

.widget select {
	max-width: 100%;
	text-overflow: ellipsis;
}

/* Archive and Categories widgets */
.widget_archive li {
	color: var(--global-palette5);
}

.widget_categories .children {
	padding-block-start: 0;
}

/* Pages and Menu widgets */
.widget_pages .children,
.widget_nav_menu .sub-menu {
	padding-block-start: 0;
	padding-block-end: 0.5em;
}

.widget_pages a,
.widget_nav_menu a {
	display: block;
	text-decoration: none;
}

.widget_pages a:hover,
.widget_pages a:focus,
.widget_nav_menu a:hover,
.widget_nav_menu a:focus {
	text-decoration: none;
}

.widget_pages li,
.widget_nav_menu li {
	padding: 0;
}

.widget_pages li ul,
.widget_nav_menu li ul {
	margin-inline-start: 1em;
}

/* Meta widget */
.widget_meta li {
	padding: 0;
}

/* Recent comments */
.widget_recent_comments a {
	font-weight: inherit;
}
.widget_recent_comments .comment-author-link a {
	font-weight: inherit;
}
.widget_recent_comments a:hover,
.widget_recent_comments a:focus {
	padding-inline-start: 0;
}
/* RSS widget */
.widget_recent_entries ul li {
    margin-block-end: 0.4em;
    .post-date {
        font-size: 0.8em;
        color: var(--global-palatte6);
    }
}

/* RSS widget */
.widget_rss li {
	margin-block-end: 1em;
	line-height: 1.3;
}

.widget li a.rsswidget {
	padding-inline-end: 0.5em;
	font-size: 1em;
	line-height: 1.4;
}

.rss-date,
.widget_rss cite {
	color:  var(--global-palatte5);
	font-size: 0.8em;
}

.rssSummary {
	padding: 0.5em 0;
	font-size: 0.9em;
	line-height: 1.3;
}

/* Calendar widget */
.widget_calendar {
	font-weight: 400;
}

.widget_calendar caption {
	padding: 0.5em 0;
	font-size: 1em;
	font-weight: 500;
	text-align: left;
}

.widget_calendar thead {
	background: transparent;
	color:  var(--global-palatte5);
}

.widget_calendar th {
	border: none;
	text-align: center;
}

.widget_calendar thead th {
	border-bottom-width: 2px;
}

.widget_calendar td {
	padding: 0.2em;
	font-size: 0.8em;
	text-align: center;
	background: #fff;
	border: 1px solid var(--global-gray-400);
}

.widget_calendar .pad {
	background: var(--global-palette7);
}
.widget_calendar .wp-calendar-nav .pad {
	background: transparent;
}

.widget_calendar a {
	font-weight: 900;
	text-decoration: none;
}
.widget {
	.tagcloud, 
	.wp-block-tag-cloud, 
	.widget_product_tag_cloud {
		a{
		font-size: 16px !important;
		display: block;
	}
}
}
.collapse-sub-navigation ul.menu {
	display: block;
	li {
		a {
			text-decoration: none;
			width: 100%;
			padding-block-start: 0.5em;
			padding-block-end: 0.5em;
			border-bottom: 1px solid var(--global-palette7);
			&:hover {
				text-decoration: none;
			}
		}
	}
	ul {
		padding: 0 0 0 1em;
		margin: 0;
		display: none;
		transition: all 0.2s ease-in-out;
	}
	.sub-menu.show-drawer {
		display: block;
	}
	.drawer-nav-drop-wrap .drawer-sub-toggle {
		border-left: 1px solid var(--global-palette7);
		border-bottom: 1px solid var(--global-palette7);
	}
}