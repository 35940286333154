@import "_custom-properties.scss";
/*--------------------------------------------------------------
# Columns
--------------------------------------------------------------*/
.grid-cols {
    display: grid;
    grid-template-columns: minmax(0, 1fr);
    column-gap: 1.875rem;
    row-gap: 1.875rem;
}
.site .grid-cols:before {
    display: none;
}
.grid-xs-col-1 {
    grid-template-columns: minmax(0, 1fr);
}
.grid-xs-col-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr) );
}
.grid-xs-col-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr) );
}
.grid-xs-col-4 {
    grid-template-columns: 1fr 1fr 1fr 1fr;
}
@media screen and (max-width: $landscape-phone-minus-query) {
	.products .grid-cols {
		grid-template-columns: repeat(2, minmax(0, 1fr) );
		column-gap: 1rem;
		row-gap: 1.5rem;
	}
}
@media screen and (min-width: $landscape-phone-plus-query) {
	.grid-sm-col-1 {
	    grid-template-columns: minmax(0, 1fr);
	}
	.grid-sm-col-2 {
	    grid-template-columns: repeat(2, minmax(0, 1fr) );
	}
	.grid-sm-col-3 {
	    grid-template-columns: repeat(3, minmax(0, 1fr) );
	}
	.grid-xl-col-6 {
	    grid-template-columns: repeat(6, minmax(0, 1fr) );
	}
	.grid-sm-col-4 {
	    grid-template-columns: 1fr 1fr 1fr 1fr;
	}
}
@media screen and (min-width: $tablet-plus-query) {
	.grid-md-col-1 {
	    grid-template-columns: minmax(0, 1fr);
	}
	.grid-md-col-2 {
	    grid-template-columns: repeat(2, minmax(0, 1fr) );
	}
	.grid-md-col-3 {
	    grid-template-columns: repeat(3, minmax(0, 1fr) );
	}
	.grid-xl-col-6 {
	    grid-template-columns: repeat(6, minmax(0, 1fr) );
	}
	.grid-md-col-4 {
	    grid-template-columns: 1fr 1fr 1fr 1fr;
	}
}
@media screen and (min-width: $laptop-plus-query) {
	.grid-lg-col-1 {
	    grid-template-columns: minmax(0, 1fr);
	}
	.grid-lg-col-2 {
	    grid-template-columns: repeat(2, minmax(0, 1fr) );
	}
	.grid-lg-col-3 {
	    grid-template-columns: repeat(3, minmax(0, 1fr) );
	}
	.grid-xl-col-6 {
	    grid-template-columns: repeat(6, minmax(0, 1fr) );
	}
	.grid-lg-col-4 {
	    grid-template-columns: 1fr 1fr 1fr 1fr;
	}
	.grid-lg-col-5 {
	    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
	}
	.grid-lg-col-6 {
	    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
	}
	.grid-lg-col-7 {
	    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
	}
}
@media screen and (min-width: $desktop-plus-query) {
	.grid-xl-col-1 {
	    grid-template-columns: minmax(0, 1fr);
	}
	.grid-xl-col-2 {
	    grid-template-columns: repeat(2, minmax(0, 1fr) );
	}
	.grid-xl-col-3 {
	    grid-template-columns: repeat(3, minmax(0, 1fr) );
	}
	.grid-xl-col-6 {
	    grid-template-columns: repeat(6, minmax(0, 1fr) );
	}
	.grid-xl-col-4 {
	    grid-template-columns: 1fr 1fr 1fr 1fr;
	}
	.grid-xl-col-5 {
	    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
	}
	.grid-xl-col-6 {
	    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
	}
	.grid-lg-col-7 {
	    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
	}
}