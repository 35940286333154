/**
 * Custom Properties
 */

$global-font-color: #666666;
$global-font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen-Sans,Ubuntu,Cantarell,"Helvetica Neue",sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
$global-font-size: 20;
$global-font-line-height: 1.4;

$color-gray-100: #FFFFFF;
$color-gray-200: #F5F5F5;
$color-gray-300: #E2E8F0;
$color-gray-400: #CBD5E0;
$color-gray-500: #A0AEC0;
$color-gray-600: #666666;
$color-gray-700: #666666;
$color-gray-800: #222222;
$color-gray-900: #222222;

$content-width: 1000px;
$dropdown-symbol-width: 0.7em;

$color-quote-border: #000;
$color-quote-citation: #6c7781;

$green:         	#7ad03a !default;
$red:           	#a00 !default;
$orange:        	#ffba00 !default;
$blue:          	#2ea2cc !default;

$secondary:         desaturate(lighten(#3182ce, 40%), 21%) !default;    // Secondary buttons
$secondarytext:     desaturate(darken($secondary, 60%), 21%) !default;   // Text on secondary color bg

$font-size-small: calc(16 / 20 * 1rem);
$font-size-regular: calc(20 / 16 * 1rem);
$font-size-large: calc(36 / 20 * 1rem);
$font-size-larger: calc(48 / 20 * 1rem);

$landscape-phone-minus-query: 540px;
$landscape-phone-plus-query: 640px;
$phone-minus-query: 719px;
$before-tablet-plus-query: 720px;
$before-tablet-query: 767px;
$tablet-plus-query: 768px;
$tablet-minus-query: 1024px;
$laptop-plus-query: 1025px;
$desktop-plus-query: 1200px;

// Social Colors.
$facebook: #3b5998;
$facebook_group: #3b5998;
$twitter: #1DA1F2;
$google: #DB4437;
$dribbble: #EA4C89;
$youtube: #FF3333;
$linkedin: #4875B4;
$flickr: #FE0883;
$tumblr: #32506d;
$vimeo: #4EBBFF;
$blogger: #FF6600;
$rss: #FF6200;
$picasa: #05A136;
$github: #4078c0;
$deviantart: #43534D;
$yahoo: #F52815;
$pinterest: #C92228;
$instagram: #517fa4;
$stumbleupon: #f74425;
$xing: #006567;
$soundcloud: #ff7700;
$yelp:#c41200;
$snapchat: #fffc00;
$vk: #45668e;
$viadeo: #F4982B;
$periscope: #3aa4c6;
$behance: #1769ff;
$reddit: #ff4500;
$medium: #181818;
$wordpress: #00749C;
$patreon: #052D49;
$email: #181818;
$phone: #181818;
$whatsapp: #28cf54;
$google_reviews: #DB4437;
$telegram: #0088cc;
$trip_advisor: #00af87;
$imdb: #F5C518;
$anchor: #5000B9;